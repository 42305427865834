import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from '../../components/slider';

// import * as styles from './teaser.module.scss';

const Teaser = ({ images, title, location, position, specification }) => {
	return (
		<Fragment>
			{Array.isArray(images) && (
				<Slider>
					{images.map((image, key) => (
						<GatsbyImage key={key} image={image.childImageSharp.gatsbyImageData} alt="" />
					))}
				</Slider>
			)}
		</Fragment>
	);
};

Teaser.defaultProps = {
	images: [],
};

Teaser.propTypes = {
	images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Teaser;
