import { graphql } from 'gatsby';

import { HTML } from '../components/utils';
import { Page } from '../components/layout';

import Teaser from './job/teaser';
// import * as styles from './job.module.scss';

const Job = ({
	data: {
		markdownRemark: {
			frontmatter: { specification, location, position, images, uuid },
			fields: { parent, slug },
			html,
		},
	},
}) => (
	<Page slug={slug} body={specification} teaser={<Teaser images={images} />}>
		<HTML content={html} />
	</Page>
);

export const jobQuery = graphql`
	query jobQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			fields {
				slug
			}
			frontmatter {
				uuid
				specification
				images {
					childImageSharp {
						gatsbyImageData(width: 900, layout: CONSTRAINED)
					}
				}
			}
			html
		}
	}
`;

export default Job;
